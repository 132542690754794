
import SeguimientoService from "@/Services/SeguimientoService";
export default {
  name: "completar-seguimiento-modal",
  props: {
    modelo: {
      type: Boolean,
      required: true,
    },
    seguimientoObj: { type: Object, required: true },
  },
  data() {
    return { comentarioCierre: "" };
  },
  methods: {
    cerrarModal() {
      this.comentarioCierre = "";
      this.$emit("cerrar-modal");
    },
    async actualizarStatus() {
      this.loader(true);
      let datos = {
        statusNuevo: this.constantesStatusCadena.seguimientoCompletado,
        comentarioCierre: this.comentarioCierre,
      };
      await SeguimientoService.actualizarStatus(
        datos,
        this.seguimientoObj.oportunidad_seguimiento_id
      )
        .then(async (resp) => {
          this.loader(false);
          this.$emit("exito", resp.mensaje);
        })
        .catch((error) => {
          this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
  },
};
