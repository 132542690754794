
import SeguimientoService from "@/Services/SeguimientoService";
import HORAS from "@/Constantes/Fecha";
import CompletarSeguimientoModal from "./componentes/CompletarSeguimientoModal.vue";
export default {
  name: "seguimiento-detalle",
  components: { CompletarSeguimientoModal },
  data() {
    return {
      breadcrumbs: [
        { etiqueta: "Seguimientos", to: { name: "seguimientosGestor" } },
        { etiqueta: "Detalle" },
      ],
      opcionesToolbar: [],
      menuStatusOpciones: [
        {
          status: this.constantesStatusCadena.seguimientoPendiente,
        },
        {
          status: this.constantesStatusCadena.seguimientoCompletado,
        },
        {
          status: this.constantesStatusCadena.seguimientoCancelado,
        },
      ],
      seguimientoId: this.$route.params.id,
      seguimiento: {},
      tipoSeguimientoOpciones: [],
      horaOpciones: HORAS.ARRAY_HORAS_POR_CUARTO,
      modalEditar: false,
      editarSeguimientoObj: {},
      recordatorioFecha: "",
      recordatorioHora: "",
      modalCompletarSeguimiento: false,
    };
  },
  mounted() {
    this.cargaInicial();
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      await this.obtenerDetalle(false);
      this.loader(false);
    },
    async obtenerDetalle(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        seguimientoId: this.seguimientoId,
      };
      await SeguimientoService.obtenerDetalle(datos)
        .then((resp) => {
          this.seguimiento = resp.info;
          this.llenarOpcionesToolbar();
          if (loader) this.loader(false);
        })
        .catch((error) => {
          if (loader) this.loader(false);
          this.mostrarAlerta(error, "error");
        });
    },
    async obtenerConcentradoOpciones(loader = true) {
      if (loader) this.loader(true);
      await SeguimientoService.obtenerConcentradoOpciones()
        .then((resp) => {
          this.tipoSeguimientoOpciones = resp.tiposSeguimientos;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async editar(loader = true) {
      // Se arma fecha de recordatorio
      let recordatorioFecha = this.dayjs(
        `${this.recordatorioFecha} ${this.recordatorioHora}`,
        "DD/MM/YYYY h:mm a"
      ).format("YYYY-MM-DD HH:mm:ss");

      // Se valida fecha de recordatorio
      let fechaActual = this.dayjs().format("YYYY-MM-DD HH:mm");
      if (this.dayjs(recordatorioFecha).isBefore(fechaActual)) {
        this.mostrarAlerta(
          `La fecha del recordatorio no puede ser menor a ${fechaActual}`,
          "error"
        );
        return "";
      }

      let datos = {
        tipoSeguimientoId:
          this.editarSeguimientoObj.oportunidad_seguimiento_tipo_id,
        comentario: this.editarSeguimientoObj.comentario,
        recordatorioFecha,
      };

      if (loader) this.loader(true);
      return SeguimientoService.editar(datos, this.seguimientoId)
        .then(async (resp) => {
          this.cerrarModalEditar();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => {
          this.mostrarAlerta(error, "error");
        })
        .finally(() => {
          if (loader) this.loader(false);
        });
    },
    async actualizarStatus(statusNuevo) {
      if (this.seguimiento.status == statusNuevo) return;

      this.loader(true);
      let datos = {
        statusNuevo: statusNuevo,
      };
      await SeguimientoService.actualizarStatus(datos, this.seguimientoId)
        .then(async (resp) => {
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    llenarOpcionesToolbar() {
      this.opcionesToolbar = [
        {
          texto: "Editar",
          icono: "editar",
          accion: "abrirModalEditar",
          disabled:
            this.seguimiento.status !=
            this.constantesStatusCadena.seguimientoPendiente,
        },
      ];
    },
    async abrirModalEditar() {
      this.editarSeguimientoObj = _.cloneDeep(this.seguimiento);
      if (_.isEmpty(this.tipoSeguimientoOpciones))
        await this.obtenerConcentradoOpciones();
      // Se arma hora en formato para captura
      let recordatorioFecha = new this.dayjs(
        this.editarSeguimientoObj.recordatorio_fecha
      );
      this.recordatorioFecha = recordatorioFecha.format("DD/MM/YYYY");
      this.recordatorioHora =
        recordatorioFecha.format("HH:mm:ss") == "00:00:00"
          ? ""
          : recordatorioFecha.format("h:mm a");
      this.modalEditar = true;
    },
    cerrarModalEditar() {
      this.editarSeguimientoObj = {};
      this.modalEditar = false;
    },
    marcarSeguimientoVencido() {
      if (
        this.seguimiento.status !=
        this.constantesStatusCadena.seguimientoPendiente
      )
        return false;
      return this.dayjs(this.seguimiento.recordatorio_fecha).isBefore(
        this.dayjs().format("DD/MMMM/YYYY HH:mm:ss")
      );
    },
    manejarActualizacionStatus(statusNuevo) {
      if (statusNuevo == this.constantesStatusCadena.seguimientoCompletado) {
        this.modalCompletarSeguimiento = true;
        return "";
      }
      return this.actualizarStatus(statusNuevo);
    },
    cerrarModalCompletarSeguimiento() {
      this.modalCompletarSeguimiento = false;
    },
    async manejarExitoCompletar(mensaje) {
      this.cerrarModalCompletarSeguimiento();
      await this.obtenerDetalle();
      this.mostrarAlerta(mensaje, "exito");
    },
  },
};
